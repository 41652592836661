import React, { useEffect, useMemo } from "react"

import { useLocation } from "@reach/router"
import Head from "decentraland-gatsby/dist/components/Head/Head"
import NotFound from "decentraland-gatsby/dist/components/Layout/NotFound"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import useAsyncMemo from "decentraland-gatsby/dist/hooks/useAsyncMemo"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import { Container } from "decentraland-ui/dist/components/Container/Container"

import PostCard from "../../../components/Blog/PostCard/PostCard"
import { LandingFooterV2 } from "../../../components/Landing/LandingFooterV2"
import BlogNavigation from "../../../components/Layout/BlogNavigation"
import Layout from "../../../components/Layout/Layout"
import Post from "../../../components/Layout/Post"
import {
  isWebpSupported,
  useImageOptimization,
} from "../../../hooks/contentful"
import { useBlogContentfulQuery } from "../../../hooks/useBlogContentfulQuery"
import { transformPostListToBlogPostAttributes } from "../../../modules/formats"
import { slugify } from "../../../modules/string"

import "./post.css"

export default function PostPage(props: any) {
  const l = useFormatMessage()

  const { getBlogPost, getBlogPosts } = useBlogContentfulQuery()

  const post = useMemo(() => {
    const post = getBlogPost(props.post)
    if (!post) {
      return null
    }
    return transformPostListToBlogPostAttributes([post])[0]
  }, [props.post])

  const location = useLocation()

  const [relatedPosts, relatedPostsState] = useAsyncMemo(
    async () => {
      if (!post) {
        return false
      }
      const { nodes: posts } = await getBlogPosts({
        offset: 0,
        limit: 4,
        categoryId: post.categoryId,
      })
      const blogpostCurated = await transformPostListToBlogPostAttributes(posts)

      return blogpostCurated.filter(
        (postCurated) => slugify(postCurated.title) !== slugify(post.title)
      )
    },
    [post],
    {
      callWithTruthyDeps: true,
    }
  )

  useEffect(() => {
    if (post && location.hash && relatedPostsState.loaded) {
      Promise.resolve().then(() => {
        const getMeTo = document.getElementById(location.hash.substring(1))
        getMeTo?.scrollIntoView({ behavior: "smooth" })
      })
    }
  }, [post, relatedPostsState])

  if (!post && relatedPostsState.loaded) {
    return (
      <Layout
        {...props}
        isFullscreen
        isOverlay
        hideFooter={false}
        className="layout__blog"
        hideSocialLinks
      >
        <BlogNavigation active={props.category} />
        <Container style={{ paddingTop: "75px" }}>
          <NotFound />
        </Container>
        <LandingFooterV2 />
      </Layout>
    )
  }

  const image = useImageOptimization(post?.image)

  return (
    <Layout
      {...props}
      isFullscreen
      isOverlay
      hideFooter={false}
      className="layout__blog"
      hideSocialLinks
    >
      <Head
        title={post?.title || ""}
        description={post?.description || ""}
        image={(isWebpSupported() && image.webp) || image.jpg || ""}
      />
      <BlogNavigation active={props.category} />

      <Post post={post} loading={relatedPostsState.loading} />

      {relatedPosts && relatedPosts.length > 0 && !relatedPostsState.loading && (
        <div className="post-related">
          <Container>
            <Title>{l("page.blog.post_related")}</Title>
            <div className="post-related__wrapper">
              {relatedPosts.slice(0, 3).map((post) => (
                <PostCard post={post} key={post.id} />
              ))}
            </div>
          </Container>
        </div>
      )}
      <LandingFooterV2 />
    </Layout>
  )
}
